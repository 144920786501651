'use client';

import {useState} from 'react';
import {useRouter} from 'next/navigation';
import {useQueryClient} from '@tanstack/react-query';
import ResponsiveModal, {type ResponsiveModalProps} from '../ResponsiveModal';
import isBoolean from 'src/utils/isBoolean';
import AuthSections from './AuthSections';

type Props = ResponsiveModalProps & {
  initialView: 'login' | 'join';
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  children?: React.ReactNode;
};

const AuthModal = ({children, initialView, isOpen, setIsOpen}: Props) => {
  const router = useRouter();
  const queryClient = useQueryClient();

  const [localOpen, setLocalOpen] = useState(Boolean(isOpen));
  const [view, setView] = useState<'login' | 'join' | 'authCode' | 'welcome'>(initialView);

  const open = isBoolean(isOpen) ? isOpen : localOpen;
  const setOpen = setIsOpen ? setIsOpen : setLocalOpen;

  const onAuthenticated = () => {
    setView('welcome');
    router.refresh();
    queryClient.resetQueries();

    setTimeout(() => {
      setOpen(false);
    }, 1500);
  };

  return (
    <ResponsiveModal trigger={children} isOpen={open} setIsOpen={setOpen} isDismissable={false}>
      <AuthSections initialView={initialView} view={view} setView={setView} onAuthenticated={onAuthenticated} />
    </ResponsiveModal>
  );
};

export default AuthModal;
