import PinField from 'react-pin-field';
import {twMerge} from 'tailwind-merge';
import * as Form from '@radix-ui/react-form';
import {differenceInMinutes} from 'date-fns';
import {useMutation} from '@tanstack/react-query';
import {type FormEvent, useEffect, useRef, useState} from 'react';
import TextInput, {getValuesFromFormEvent, textInputClassName} from './form/TextInput';
import {authErrors} from 'src/utils/errors';
import fetcher from 'src/utils/fetcher';
import FormErrors from './FormErrors';
import BaseButton from './BaseButton';

type EditableValues = {
  code: string;
};

type Props = {
  initialAuthSession: AuthSession;
  onSuccess: (session: IronSession) => void;
  buttonText: string;
};

const getMinutesDifference = (date: Date) => {
  return differenceInMinutes(new Date(date), new Date()) + 1;
};

const AuthForm = ({initialAuthSession, onSuccess, buttonText}: Props) => {
  const lastCompleteCode = useRef<string>('');

  const [regenAuthSession, setRegenAuthSession] = useState<AuthSession>();
  const [showCodeSentMessage, setShowCodeSentMessage] = useState(false);

  const authSession = regenAuthSession || initialAuthSession;

  const {
    mutateAsync: authenticate,
    isPending,
    error,
    reset: resetAuthentication
  } = useMutation({
    mutationFn: (values: EditableValues) =>
      fetcher<IronSession>({
        url: '/api/auth/authenticate',
        options: {method: 'POST'},
        body: {...values, authSessionId: authSession._id, source: 'web'},
        disableRedirect: true
      }),
    onSuccess: (session) => {
      onSuccess(session);
    }
  });

  const {mutateAsync: regenerateAuthSession, error: regenError} = useMutation({
    mutationFn: () =>
      fetcher<AuthSession>({
        url: '/api/auth/regenerate',
        options: {method: 'POST'},
        body: {authSessionId: authSession._id}
      }),
    onSuccess: (result) => {
      resetAuthentication();
      setRegenAuthSession(result);
      setMinutesRemaining(getMinutesDifference(result.dateExpiration));
      setShowCodeSentMessage(true);

      setTimeout(() => {
        setShowCodeSentMessage(false);
      }, 5000);
    }
  });

  const [minutesRemaining, setMinutesRemaining] = useState(getMinutesDifference(authSession.dateExpiration));

  useEffect(() => {
    let timeout: any;

    const setTime = () => {
      timeout = setTimeout(() => {
        setMinutesRemaining(getMinutesDifference(authSession.dateExpiration));
        setTime();
      }, 10000);
    };

    setTime();

    return () => clearTimeout(timeout);
  }, []);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    const values = getValuesFromFormEvent(event, ['code']) as EditableValues;

    event.preventDefault();

    authenticate(values);
  };

  return (
    <Form.Root className="flex flex-col pt-3" onSubmit={onSubmit}>
      <p className="text-lg/normal mb-5 mr-8">
        Great! We've just sent a secret code to {authSession?.email}. Just fill in the code to sign in!
      </p>

      {authSession?.codeType === 'short' ? (
        <div className="flex [&>input]:w-0 [&>input]:grow gap-4 mb-4">
          <PinField
            length={4}
            required
            type="numeric"
            inputMode="numeric"
            validate="0123456789"
            onComplete={(value) => {
              if (value === lastCompleteCode.current) return;

              lastCompleteCode.current = value;
              authenticate({code: value});
            }}
            className={twMerge(textInputClassName, 'text-center py-6 text-4xl')}
          />
        </div>
      ) : (
        <TextInput autoComplete={false} required name="code" label="Secret code" />
      )}

      <div className="mb-4">
        {minutesRemaining >= 0 ? <span>You have {minutesRemaining} minutes.</span> : null}
        {minutesRemaining < 0 ? <span className="text-(--error-color)">The code expired.</span> : null}

        {showCodeSentMessage ? (
          <BaseButton type="button" className="inline h-auto py-2 px-2 ml-2" buttonVariant="text">
            Check your inbox!
          </BaseButton>
        ) : (
          <BaseButton
            color="var(--purple-text)"
            className="inline h-auto py-2 px-2 ml-2"
            buttonVariant="text"
            onPress={() => regenerateAuthSession()}
            type="button"
          >
            Send me the code again
          </BaseButton>
        )}
      </div>

      <FormErrors errors={error || regenError} knownErrors={authErrors} className="mb-6" />

      <Form.Submit asChild>
        <BaseButton
          isLoading={isPending}
          type="submit"
          label={buttonText}
          buttonVariant="primary"
          className="grow"
        />
      </Form.Submit>
    </Form.Root>
  );
};

export default AuthForm;
