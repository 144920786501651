'use client';

import {twMerge} from 'tailwind-merge';
import {forwardRef, useState} from 'react';
import {Menu, MenuItem, MenuTrigger, Popover, Separator} from 'react-aria-components';
import isBoolean from 'src/utils/isBoolean';
import RemixIcon from './RemixIcon';

export type FullDropdownMenuItem = {
  text?: string;
  onAction?: () => void;
  href?: string;
  target?: '_blank';
  icon?: any;
  iconComponent?: React.ReactNode;
  items?: DropdownMenuItem[];
  component?: React.ReactNode;
  keepOpened?: boolean;
  key?: string;
};

export type DropdownMenuItem = 'separator' | FullDropdownMenuItem;

type MenuItemProps = {
  item: DropdownMenuItem;
};

const MenuItemContent = forwardRef(({item}: MenuItemProps, ref: any) => {
  if (item === 'separator') return null;

  return (
    <div className="flex items-center text-base/snug font-semibold text-[white]" ref={ref}>
      {item.iconComponent}
      {item.icon ? <RemixIcon className="mr-2" icon={item.icon} color="white" size={18} /> : null}
      {item.text}
    </div>
  );
});

type Props = {
  children: React.ReactNode;
  items: DropdownMenuItem[];
  align?: 'start' | 'end';
  controlledIsOpen?: boolean;
  controlledSetIsOpen?: (isOpen: boolean) => void;
};

const Dropdown = ({children, items, align = 'end', controlledIsOpen, controlledSetIsOpen}: Props) => {
  const [localIsOpen, localSetIsOpenState] = useState(false);

  const open = isBoolean(controlledIsOpen) ? controlledIsOpen : localIsOpen;
  const setOpen = controlledSetIsOpen || localSetIsOpenState;

  return (
    <MenuTrigger isOpen={open} onOpenChange={setOpen}>
      {children}

      <Popover
        placement={align === 'start' ? 'bottom start' : 'bottom end'}
        crossOffset={align === 'start' ? 0 : -8}
        className={twMerge(
          'rounded-[11px] bg-(--purple) min-w-[160px] max-w-[340px] p-1 overflow-auto shadow-[0px_2px_5px_rgba(101,39,131,0.2),0px_14px_50px_rgba(101,39,131,0.5)] dark:shadow-[inset_0_0_0_1px_rgb(255_255_255_/_0.2)] data-entering:animate-show-up data-exiting:animate-show-up-reverse',
          align === 'start'
            ? 'data-[placement=bottom]:origin-top-left data-[placement=top]:origin-bottom-left'
            : 'data-[placement=bottom]:origin-top-right data-[placement=top]:origin-bottom-right'
        )}
      >
        <Menu selectionMode="multiple">
          {items.map((item, index) => {
            if (item === 'separator')
              return <Separator key={index} className="h-px -ml-1 -mr-1 my-1 bg-[rgba(255,255,255,0.1)]" />;

            if (item.component) return item.component;

            return (
              <MenuItem
                key={index}
                href={item.href}
                onAction={() => {
                  if (item.onAction) item.onAction();
                  if (!item.keepOpened) setOpen(false);
                }}
                className="flex min-h-[38px] items-center rounded-lg py-1 px-2 relative cursor-pointer focus:bg-[rgb(255_255_255/0.08)] focus:data-[pressed]:bg-[rgb(255_255_255/0.12)]"
              >
                <MenuItemContent item={item} />
              </MenuItem>
            );
          })}
        </Menu>
      </Popover>
    </MenuTrigger>
  );
};

export default Dropdown;
