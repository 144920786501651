import type {FormEvent} from 'react';
import * as Form from '@radix-ui/react-form';
import {useMutation} from '@tanstack/react-query';
import TextInput, {getValuesFromFormEvent} from './form/TextInput';
import {loginErrors} from 'src/utils/errors';
import fetcher from 'src/utils/fetcher';
import FormErrors from './FormErrors';
import BaseButton from './BaseButton';
import LogoIcon from './LogoIcon';

type EditableValues = {
  email: string;
};

type Props = {
  goToJoin: () => void;
  setAuthSession: (data: any) => void;
};

const LoginForm = ({goToJoin, setAuthSession}: Props) => {
  const {
    mutateAsync: signIn,
    isPending,
    error
  } = useMutation({
    mutationFn: (values: EditableValues) =>
      fetcher({
        url: '/api/auth/login',
        options: {method: 'POST'},
        body: values
      }),
    onSuccess: (data) => setAuthSession(data)
  });

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    const values = getValuesFromFormEvent(event, ['email']) as EditableValues;

    event.preventDefault();

    signIn(values);
  };

  return (
    <Form.Root className="flex flex-col pt-3" onSubmit={onSubmit}>
      <div className="flex flex-col items-center">
        <LogoIcon width={40} height={46} />

        <h2 className="text-4xl text-center text-(--purple-text) mt-4">Log in</h2>

        <p className="text-lg/snug text-center [text-wrap:balance] mt-2 mb-9">
          Hello again, let's start with your email so that we can send you a sign-in code.
        </p>
      </div>

      <TextInput
        name="email"
        label="Email"
        required
        type="email"
        validations={[{match: 'valueMissing', children: 'What is your email?'}]}
      />

      <div className="mb-6">
        <span className="text-(--gray-text)">Don't have an account?</span>
        <BaseButton
          className="inline h-auto py-1 px-2 ml-1"
          color="var(--purple-text)"
          buttonVariant="text"
          onPress={goToJoin}
          label="Join Humbo"
          type="button"
        />
      </div>

      <FormErrors errors={error} knownErrors={loginErrors} className="mb-6" />

      <Form.Submit asChild>
        <BaseButton type="submit" label="Continue" buttonVariant="primary" className="grow" isLoading={isPending} />
      </Form.Submit>
    </Form.Root>
  );
};

export default LoginForm;
