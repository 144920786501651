import {twJoin, twMerge} from 'tailwind-merge';
import {MenuItem, MenuSection} from 'react-aria-components';
import {magicLine, moonLine, sunLine} from 'src/utils/icons';
import useDarkMode from 'src/hooks/useDarkMode';
import useMounted from 'src/hooks/useMounted';
import RemixIcon from '../RemixIcon';

type Props = {
  className?: string;
};

const ThemeToggle = ({className}: Props) => {
  const {setTheme, theme} = useDarkMode();
  const mounted = useMounted();

  const value = mounted ? theme : 'system';

  return (
    <MenuSection className={twMerge('flex grow mb-1 gap-1', className)}>
      <MenuItem
        onAction={() => setTheme('light')}
        className={twJoin(
          'flex items-center justify-center rounded-lg px-2 py-2 grow cursor-pointer focus:bg-[rgb(255_255_255/0.08)] focus:data-[pressed]:bg-[rgb(255_255_255/0.12)]',
          value === 'light' && 'bg-[rgb(255_255_255/0.12)]'
        )}
      >
        <RemixIcon icon={sunLine} color="white" size={18} />
      </MenuItem>

      <MenuItem
        onAction={() => setTheme('dark')}
        className={twJoin(
          'flex items-center justify-center rounded-lg px-2 py-2 grow cursor-pointer focus:bg-[rgb(255_255_255/0.08)] focus:data-[pressed]:bg-[rgb(255_255_255/0.12)]',
          value === 'dark' && 'bg-[rgb(255_255_255/0.12)]'
        )}
      >
        <RemixIcon icon={moonLine} color="white" size={18} />
      </MenuItem>

      <MenuItem
        onAction={() => setTheme('system')}
        className={twJoin(
          'flex items-center justify-center rounded-lg px-2 py-2 grow cursor-pointer focus:bg-[rgb(255_255_255/0.08)] focus:data-[pressed]:bg-[rgb(255_255_255/0.12)]',
          value === 'system' && 'bg-[rgb(255_255_255/0.12)]'
        )}
      >
        <RemixIcon icon={magicLine} color="white" size={18} />
      </MenuItem>
    </MenuSection>
  );
};

export default ThemeToggle;
