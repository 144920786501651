'use client';

import {useState} from 'react';
import {LazyMotion, m as motion} from 'motion/react';
import SignUpForm from '../SignUpForm';
import LoginForm from '../LoginForm';
import AuthForm from '../AuthForm';
import LogoIcon from '../LogoIcon';

const allAnimations = () => import('src/utils/motionFeatures').then((res) => res.domMax);

type Props = {
  initialView: 'login' | 'join';
  view: 'login' | 'join' | 'authCode' | 'welcome';
  setView: (view: 'login' | 'join' | 'authCode' | 'welcome') => void;
  onAuthenticated: (session: IronSession) => void;
};

const AuthSections = ({view, setView, onAuthenticated, initialView}: Props) => {
  const [authSession, setLocalAuthSession] = useState<AuthSession>();

  const setAuthSession = (authSession: AuthSession) => {
    if (!authSession) return;

    setLocalAuthSession(authSession);
    setView('authCode');
  };

  return (
    <LazyMotion features={allAnimations} strict>
      {view === 'login' ? (
        <motion.div layout>
          <LoginForm setAuthSession={setAuthSession} goToJoin={() => setView('join')} />
        </motion.div>
      ) : null}

      {view === 'join' ? (
        <motion.div layout>
          <SignUpForm setAuthSession={setAuthSession} goToLogin={() => setView('login')} />
        </motion.div>
      ) : null}

      {view === 'authCode' && authSession ? (
        <motion.div layout>
          <AuthForm
            initialAuthSession={authSession}
            onSuccess={onAuthenticated}
            buttonText={initialView === 'join' ? 'Join' : 'Log in'}
          />
        </motion.div>
      ) : null}

      {view === 'welcome' ? (
        <motion.div layout className="flex flex-col items-center my-10">
          <LogoIcon width={40} height={46} />

          <h2 className="text-2xl text-center mt-5">
            {`Welcome ${initialView === 'join' ? '' : 'back '}${authSession?.name}!`}
          </h2>
        </motion.div>
      ) : null}
    </LazyMotion>
  );
};

export default AuthSections;
