'use client';

import {useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {m as motion, LayoutGroup, LazyMotion} from 'motion/react';
import {ButtonContext, Dialog, Modal, ModalOverlay} from 'react-aria-components';
import isBoolean from 'src/utils/isBoolean';
import {closeLine} from 'src/utils/icons';
import BaseButton from './BaseButton';

// @ts-ignore
const AnimatedModal = motion.create(Modal);

const allAnimations = () => import('src/utils/motionFeatures').then((res) => res.domMax);

export const modalTransition = {type: 'spring', duration: 0.4, bounce: 0.2};

export type ResponsiveModalProps = {
  onClose?: () => void;
  children?: any;
  trigger?: React.ReactNode;
  title?: string;
  ariaLabel?: string;
  className?: string;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  closeOnEscape?: boolean;
  isVisuallyHidden?: boolean;
  isDismissable?: boolean;
};

const ResponsiveModal = ({
  onClose,
  children,
  trigger,
  title,
  className,
  isOpen,
  setIsOpen,
  ariaLabel,
  isVisuallyHidden,
  closeOnEscape = true,
  isDismissable = true
}: ResponsiveModalProps) => {
  const [localOpen, setLocalOpen] = useState(Boolean(isOpen));

  const open = isBoolean(isOpen) ? isOpen : localOpen;
  const setOpen = setIsOpen ? setIsOpen : setLocalOpen;

  const onOpenChange = (open: boolean) => {
    setOpen(open);

    if (!open && onClose) onClose();
  };

  return (
    <LazyMotion features={allAnimations} strict>
      <ButtonContext.Provider value={{onPress: () => onOpenChange(true)}}>{trigger}</ButtonContext.Provider>

      <ModalOverlay
        isDismissable={isDismissable}
        isKeyboardDismissDisabled={!closeOnEscape}
        isOpen={open}
        onOpenChange={onOpenChange}
        className="fixed top-0 h-(--visual-viewport-height) bg-[rgba(0,0,0,0.3)] data-entering:bg-transparent data-exiting:bg-transparent duration-300 right-0 left-0 z-20 flex flex-col items-center justify-center"
      >
        <AnimatedModal
          layout
          transition={modalTransition}
          style={{borderRadius: 24}}
          layoutScroll
          className={twMerge(
            'relative z-21 [&_input]:focus:animate-[appear_0.001s] [&_textarea]:focus:animate-[appear_0.001s] bg-[rgb(255_255_255_/_0.85)] dark:bg-[rgb(22_15_25_/_0.85)] backdrop-blur-lg backdrop-saturate-150 w-[420px] max-w-[calc(100%-20px)] min-h-[100px] max-h-[calc(100%-20px)] shadow-[inset_0_0_1px_1px_rgb(255_255_255_/_0.5),0_0_0_1px_rgb(23_10_37_/_0.1),0_3px_20px_rgb(23_10_37_/_0.06),0_20px_80px_rgb(23_10_37_/_0.1)] dark:shadow-[inset_0_0_1px_1px_rgb(255_255_255_/_0.1),0_0_0_1px_rgb(23_10_37_/_0.1),0_3px_20px_rgb(23_10_37_/_0.06),0_20px_80px_rgb(23_10_37_/_0.1)] p-6 overflow-y-auto rounded-[24px] data-entering:animate-show-up data-exiting:animate-show-up-reverse',
            isVisuallyHidden ? 'opacity-0' : '',
            className
          )}
        >
          <Dialog aria-label={ariaLabel || title}>
            {({close}) => (
              <LayoutGroup>
                {title ? (
                  <motion.h2 layout className="text-lg leading-tight pr-4 -mt-1" transition={modalTransition}>
                    {title}
                  </motion.h2>
                ) : null}

                <motion.div layout className="absolute right-4 top-4" transition={modalTransition}>
                  <BaseButton
                    onPress={close}
                    icon={closeLine}
                    buttonVariant="secondary"
                    className="w-[32px] h-[32px]"
                  />
                </motion.div>

                {children}
              </LayoutGroup>
            )}
          </Dialog>
        </AnimatedModal>
      </ModalOverlay>
    </LazyMotion>
  );
};

export default ResponsiveModal;
